import axios from "axios";

class AwsApiService {
  // Handlers
  async handleAddScheduler(
    ipAddr: string,
    computerName: string,
    grName: string
  ) {
    try {
      const url = "/prod/save";
      const command = `
      computer_name=${computerName}
   		group_name=${grName}
			echo "$computer_name" > /etc/.computer
   			echo "$group_name" > /etc/.group
      
			# 스크립트 내용을 restart_all.sh 파일에 작성
			cat << 'EOF' > /bin/restart_all.sh
#!/bin/bash

# 모든 도커 컨테이너 ID를 가져옴
containers=$(docker ps -a -q)

# 각 컨테이너에 대해 명령어 실행
for container in $containers; do
	/usr/bin/docker start $container
	/usr/bin/docker exec -i $container saseul-script start
	sleep 5
	/usr/bin/docker exec -i $container saseul-script startmining
done`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  // 11)
  async handleCreateNodes(
    ipAddr: string,
    startPort: string,
    nodesCount: string,
    minerAddr: string
  ) {
    try {
      const url = "/prod/save";
      const command = `
      start_port=${startPort}
			count=${nodesCount}
			miner=${minerAddr}
			tar -zxvf saseuldata.tar.gz
		    myip=$(wget -qO- ipinfo.io/ip)
			for ((i=1; i<=$count; i++)); do
			    port=$((i+$start_port-1))
			    docker stop saseul-node$port > /dev/null
			    docker rm saseul-node$port > /dev/null
			    # env_content=$(cat $user_home/saseul-data$port/env)
			    rm -rf $user_home/saseul-data$port > /dev/null
			    cp -r saseul-data saseul-data$port > /dev/null
				
			    echo "$port Copy Complete"
			    
			    docker run -d --init --name saseul-node$port -p $port:80 -v $user_home/saseul-data$port:/var/saseul/saseul-network/data artifriends/saseul-network:latest
			    # echo "$port setenv Start"
			    
				# env_content 변수의 내용을 확인합니다.
				# if [[ -n "$env_content" ]]; then
				#     # env_content 변수가 설정되어 있다면 파일에 저장합니다.
				#     echo "$env_content" > $user_home/saseul-data$port/env
				# else
				#     # env_content 변수가 설정되어 있지 않다면 docker exec를 사용합니다.
				#     # run_with_yes라는 함수나 스크립트가 미리 정의되어 있어야 합니다.
				#     # run_with_yes docker exec -i saseul-node$port saseul-script SetEnv
				# fi
				sleep 1
			    run_with_yes docker exec -i saseul-node$port saseul-script SetEnv
       
			    sleep 5
			    echo "$port setenv endpoint 시작"
			    run_with_yes docker exec -i saseul-node$port saseul-script SetEnv --endpoint $myip:$port -m $miner
			    docker exec -i saseul-node$port saseul-install > /dev/null
			    docker exec -i saseul-node$port saseul-script start /dev/null
			    
			    sleep 3
			    docker exec -i saseul-node$port saseul-script startmining /dev/null
			    docker exec -i saseul-node$port saseul-script info
			done`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  //=======================================================
  //12)
  async handleModifyNodes(
    ipAddr: string,
    startPort: string,
    nodesCount: string,
    minerAddr: string
  ) {
    try {
      const url = "/prod/save";
      const command = `
      start_port=${startPort}
			count=${nodesCount}
			miner=${minerAddr}
		    myip=$(wget -qO- ipinfo.io/ip)
			for ((i=1; i<=$count; i++)); do
			    port=$((i+$start_port-1))
			    echo "saseul-node$port 변경 시작"
				# env_content 변수의 내용을 확인합니다.
			    run_with_yes docker exec -i saseul-node$port saseul-script SetEnv
			    run_with_yes docker exec -i saseul-node$port saseul-script SetEnv --endpoint $myip:$port -m $miner
			    docker exec -i saseul-node$port saseul-script start /dev/null
			    
			    sleep 3
			    docker exec -i saseul-node$port saseul-script startmining /dev/null
			    docker exec -i saseul-node$port saseul-script info
			done`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  //=======================================================

  async handleCopyFrom(ipAddr: any, from_port: any, to_port: any) {
    try {
      const url = "/prod/save";
      const script = `
myip=\$(wget -qO- ipinfo.io/ip)
from_port=${from_port}
to_port=${to_port}
declare -a ADDR=(\$(echo "\${to_port}" | tr ',' ' '))

miner=\$(docker exec -i saseul-node\${from_port} saseul-script getenv -m)
for i in "\${ADDR[@]}"; do
  if [[ "\$i" =~ ^[0-9]+-[0-9]+$ ]]; then
    rangeStart=\${i%-*}
    rangeEnd=\${i#*-}
    for ((j=\$rangeStart; j<=\$rangeEnd; j++)); do
      echo "Processing port \$j"
      docker rm \$(docker stop saseul-node\$j) > /dev/null
      rm -rf \$user_home/saseul-data\$j > /dev/null
      cp -r \$user_home/saseul-data\${from_port} \$user_home/saseul-data\$j > /dev/null

      docker run -d --init --name saseul-node\$j -p \$j:80 -v \$user_home/saseul-data\$j:/var/saseul/saseul-network/data artifriends/saseul-network:latest
      sleep 2
      run_with_yes docker exec -i saseul-node\$j saseul-script setenv
      sleep 5
      docker exec -i saseul-node\$j saseul-script setenv -e \$myip:\$j -m \$miner
      docker exec -i saseul-node\$j saseul-script start

      sleep 3
      docker exec -i saseul-node\$j saseul-script startmining
      docker exec -i saseul-node\$j saseul-script info
      docker exec -i saseul-node\$j saseul-script getenv
    done
  elif [[ "\$i" =~ ^[0-9]+$ ]]; then
    echo "Processing individual port \$i"
    # Similar logic as above for individual ports
  else
    echo "Invalid input: \$i"
  fi
done
`.trim();

      const data = {
        ip: ipAddr,
        cmd: script,
        timestamp: new Date().toISOString(),
      };
      await axios.post(url, data).then(() => {
        console.log("submitted");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  //=======================================

  async handleAllStart(ipAddr: any) {
    try {
      const url = "/prod/save";
      const command = `
      for name in $(docker ps -a --format "{{.Names}}"); do
      docker start $name > /dev/null
      
        printf "%-20s %-10s\n" "$name" "started"
    done
      
    for name in $(docker ps --format "{{.Names}}"); do
      docker exec -i $name saseul-script start > /dev/null
      sleep 1
      docker exec -i $name saseul-script startmining > /dev/null
        printf "%-20s %-10s\n" "$name" "started"
    done`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      console.log("data::::", data);
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  //================================

  async handleDeletePort(ipAddr: any, port: any) {
    try {
      const url = "/prod/save";
      const command = `
      port=${port}
      docker rm -f $(docker stop saseul-node$port)`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async handleDeleteAll({ ipAddr }: { ipAddr: any }) {
    try {
      const url = "/prod/save";
      const command = `
        docker rm -f $(docker stop saseul-node$port)
        rm -rf $user_home/saseul-data*
        rm -rf $user_home/saseuldata.tar.gz`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  // 99)
  async handleBackup(ipAddr: any, port: any) {
    try {
      const url = "/prod/save";
      const command = `
      port=${port}
      rm -rf $user_home/saseul-data $user_home/saseuldata.tar.gz
      cp -r $user_home/saseul-data$port $user_home/saseul-data
      tar -zcvf $user_home/saseuldata.tar.gz $user_home/saseul-data`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async handleRestart(ipAddr: any, node_id: any) {
    try {
      const url = "/prod/save";
      const command = `docker exec -i ${node_id} restart`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async handleStop(ipAddr: any, node_id: any) {
    try {
      const url = "/prod/save";
      const command = `docker exec -i ${node_id} restart`;

      const data = {
        ip: ipAddr,
        cmd: command,
        timestamp: new Date().toLocaleString() + "",
      };
      await axios.post(url, data).then(() => {
        alert("Submitted successfully!");
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default AwsApiService;
