import "../styles/dashboard.css";
export function Dashboard() {
  return (
    <div className="dashboard_container">
      <section className="main">
        <div className="info">
          <div className="total">
            <p className="total-title">Total</p>
            <p className="total-count">50,000,000</p>
          </div>
          <select className="select">
            <option className="option" value="hide">
              -- 종류 --
            </option>
            <option className="option" value="sample">
              -- sample --
            </option>
          </select>
        </div>
        <table className="table">
          {/* header */}
          <th>Date</th>
          <th>Amount</th>
          <th>...</th>
          <th>...</th>
          <th>Ect</th>
          {/* row */}
          <tr>
            <td className="none">24.01.01</td>
            <td>50,000,000</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="none">24.01.02</td>
            <td>49,000,560</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="none">24.01.03</td>
            <td>80,000,000</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </section>
    </div>
  );
}
