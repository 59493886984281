import React from "react";
import LoginForm from "./loginForm";
import { useNavigate } from "react-router-dom";

interface LoginPageProps {
  onLoginSuccess: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const handleLogin = (username: string, password: string) => {
    const userName = "seoullabs";
    const pwd = "monit123";

    if (username === userName && password === pwd) {
      alert("Login Successful");
      onLoginSuccess();
      navigate("/");
    } else {
      alert("Login Failed");
    }
  };

  return (
    <div>
      <LoginForm onLogin={handleLogin} />
    </div>
  );
};

export default LoginPage;
