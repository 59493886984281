import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import RestartButton from "../components/restartButton";
import StopButton from "../components/stopButton";
import AwsApiService from "../services/awsApiService";
import "../styles/nodes.css";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, DialogTitle, Drawer, ModalClose } from "@mui/joy";
import RestartAllButton from "../components/RestartAllButton";
import DeleteAllButton from "../components/DeleteAllButton";

export function Nodes() {
  const [data, setData] = useState({ pc: [] });
  const [loading, setLoading] = useState(true);
  const [lastBlockData, setLastBlockData] = useState<any>(null);
  const [sortedData, setSortedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [ipInput, setIpInput] = useState("");
  const [ipInput2, setIpInput2] = useState("");
  const [ipInput3, setIpInput3] = useState("");
  const [fromPort, setFromPort] = useState("");
  const [ipInput4, setIpInput4] = useState("");
  const [ipInput5, setIpInput5] = useState("");
  const [ipInput6, setIpInput6] = useState("");
  const [port, setPort] = useState("");
  const [backupPort, setBackupPort] = useState("");

  const [toPort, setToPort] = useState("");
  const [computerName, setComputerName] = useState("");
  const [grName, setGrName] = useState("");
  const [startPort, setStartPort] = useState("");
  const [startPort_2, setStartPort_2] = useState("");
  const [nodesCount, setNodesCount] = useState("");
  const [nodesCount_2, setNodesCount_2] = useState("");
  const [minerAddr, setMinerAddr] = useState("");
  const [minerAddr_2, setMinerAddr_2] = useState("");
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // const apiUrl = process.env.REACT_APP_API_URL;

  // APIs
  const apiUrl =
    "https://9cd299pxj8.execute-api.us-east-1.amazonaws.com/prod/pc";

  const lastBlockUrl = "https://paul.zigap.io/info";

  useEffect(() => {
    const groupName = localStorage.getItem("groupName"); // Fetch from localStorage
    if (!groupName) {
      console.log("Group not found");
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await fetch(`${apiUrl}?group=${groupName}`);
        const items = await result.json();

        setData(items);
        setLoading(false);
      } catch (error) {
        console.error("Error loading data", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Fetch last block data
  useEffect(() => {
    const fetchLastBlockData = async () => {
      try {
        const response = await fetch(lastBlockUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch last block data");
        }
        const zigapData = await response.json();
        setLastBlockData(zigapData);
      } catch (error) {
        console.error("Error fetching last block data", error);
      }
    };

    fetchLastBlockData();
  }, []);

  useEffect(() => {
    if (data?.pc?.length > 0) {
      setFilteredData(data.pc);
    }
  }, [data.pc]);

  useEffect(() => {
    if (filteredData?.length > 0) {
      const sortedItems = [...filteredData].sort((a: any, b: any) => {
        const crashedNodesA: any = numberOfCrashedNodes(a.nodes);
        const crashedNodesB: any = numberOfCrashedNodes(b.nodes);
        return crashedNodesB - crashedNodesA;
      });
      setSortedData(sortedItems);
    }
  }, [filteredData]);

  // ====================== Submit handlers =============================
  const handleIpInput = (e: any) => {
    setIpInput(e.target.value);
  };

  const handleSubmitFromTo = (e: any) => {
    e.preventDefault();
    const awsService = new AwsApiService();
    awsService.handleCopyFrom(ipInput, fromPort, toPort);
    window.location.reload();
    alert("submitted successfully!");
  };

  const handleFromInput = (e: any) => {
    setFromPort(e.target.value);
  };

  const handleToInput = (e: any) => {
    setToPort(e.target.value);
  };

  //======================

  const handleIpInput2 = (e: any) => {
    setIpInput2(e.target.value);
  };

  const handleSubmitScheduler = (e: any) => {
    e.preventDefault();
    const awsService = new AwsApiService();
    awsService.handleAddScheduler(ipInput2, computerName, grName);
    window.location.reload();
    alert("submitted successfully!");
  };

  const handlePCNameInput = (e: any) => {
    setComputerName(e.target.value);
  };

  const handleGrNameInput = (e: any) => {
    setGrName(e.target.value);
  };

  //==============
  const handleIpInput3 = (e: any) => {
    setIpInput3(e.target.value);
  };

  const handleSubmitCreate = (e: any) => {
    e.preventDefault();
    const awsService = new AwsApiService();
    awsService.handleCreateNodes(ipInput3, startPort, nodesCount, minerAddr);
    window.location.reload();
    alert("submitted successfully!");
  };

  const handleStartPort = (e: any) => {
    setStartPort(e.target.value);
  };

  const handleNodesCount = (e: any) => {
    setNodesCount(e.target.value);
  };

  const handleMinerAddress = (e: any) => {
    setMinerAddr(e.target.value);
  };

  //==============

  const handleIpInput4 = (e: any) => {
    setIpInput4(e.target.value);
  };

  const handleSubmitModify = (e: any) => {
    e.preventDefault();
    const awsService = new AwsApiService();
    awsService.handleModifyNodes(
      ipInput4,
      startPort_2,
      nodesCount_2,
      minerAddr_2
    );
    window.location.reload();
    alert("submitted successfully!");
  };

  const handleStartPort_2 = (e: any) => {
    setStartPort_2(e.target.value);
  };

  const handleNodesCount_2 = (e: any) => {
    setNodesCount_2(e.target.value);
  };

  const handleMinerAddress_2 = (e: any) => {
    setMinerAddr_2(e.target.value);
  };

  //=================

  const handleIpInput5 = (e: any) => {
    setIpInput5(e.target.value);
  };

  const handleSubmitDelete = (e: any) => {
    e.preventDefault();
    const awsService = new AwsApiService();
    awsService.handleDeletePort(ipInput5, port);
    window.location.reload();
    alert("submitted successfully!");
  };

  const handleDeletePort = (e: any) => {
    setPort(e.target.value);
  };

  //=======================

  const handleIpInput6 = (e: any) => {
    setIpInput6(e.target.value);
  };

  const handleSubmitBackup = (e: any) => {
    e.preventDefault();
    const awsService = new AwsApiService();
    awsService.handleBackup(ipInput6, backupPort);
    window.location.reload();
    alert("submitted successfully!");
  };

  const handleBackupPort = (e: any) => {
    setBackupPort(e.target.value);
  };

  //=============== Filters ===================

  function normalNodes(nodes: any, ip: string) {
    return Object.entries(nodes)
      .filter(([nodeId, nodeData]: any) => {
        if (lastBlockData) {
          return (
            lastBlockData?.data.last_resource_block.height - nodeData[5] < 20
          );
        } else {
          return false;
        }
      })
      .map(([nodeId, nodeData]: any, index) => (
        <div key={index} className="nodes-id">
          <p>{nodeId}</p>
          <p style={{ color: "skyblue" }}>({nodeData[5]})</p>
          {/*<div className={"button_container"}>*/}
          {/*  <RestartButton nodeId={nodeId} ip={ip} />*/}
          {/*  <StopButton nodeId={nodeId} ip={ip} />*/}
          {/*</div>*/}
        </div>
      ));
  }

  // Filters
  function crashedNodes(nodes: any, ip: string) {
    if (!lastBlockData) return null;
    return Object.entries(nodes)
      .filter(([nodeId, nodeData]: any) => {
        if (lastBlockData) {
          return (
            lastBlockData?.data.last_resource_block.height - nodeData[5] > 60
          );
        } else {
          return false;
        }
      })
      .map(([nodeId, nodeData]: any, index) => (
        <div key={index} className="nodes-id">
          <p>{nodeId}</p>
          <p style={{ color: "#FA7070" }}>({nodeData[5]})</p>
          {/* <div className={"button_container"}>
            <RestartButton nodeId={nodeId} ip={ip} />
            <StopButton nodeId={nodeId} ip={ip} />
          </div> */}
        </div>
      ));
  }

  function issueNodes(nodes: any, ip: string) {
    if (!lastBlockData) return null;
    return Object.entries(nodes)
      .filter(([nodeId, nodeData]: any) => {
        if (lastBlockData) {
          const diff =
            lastBlockData?.data.last_resource_block.height - nodeData[5];
          return diff >= 20 && diff < 60;
        } else {
          return false;
        }
      })
      .map(([nodeId, nodeData]: any, index) => (
        <div key={index} className="nodes-id" style={{ gap: "5px" }}>
          <p>{nodeId}</p>
          <p style={{ color: "#FDE767" }}>({nodeData[5]})</p>
          {/* <div className={"button_container"}>
            <RestartButton nodeId={nodeId} ip={ip} />
            <StopButton nodeId={nodeId} ip={ip} />
          </div> */}
        </div>
      ));
  }

  //================= Filter: number of nodes  =============================

  function numberOfIssueNodes(nodes: any) {
    if (!lastBlockData) return null;
    const filteredNodes = Object.entries(nodes).filter(
      ([nodeId, nodeData]: any) => {
        if (lastBlockData) {
          const diff =
            lastBlockData?.data.last_resource_block.height - nodeData[5];
          return diff >= 20 && diff < 60;
        } else {
          return false;
        }
      }
    );
    return filteredNodes.length;
  }

  function numberOfCrashedNodes(nodes: any) {
    if (!lastBlockData) return null;
    const filteredNodes = Object.entries(nodes).filter(
      ([nodeId, nodeData]: any) => {
        if (lastBlockData) {
          return (
            lastBlockData.data.last_resource_block.height - nodeData[5] > 60
          );
        } else {
          return false;
        }
      }
    );
    return filteredNodes.length;
  }

  function numberOfNormalNodes(nodes: any) {
    if (!lastBlockData) return null;
    const filteredNodes = Object.entries(nodes).filter(
      ([nodeId, nodeData]: any) => {
        if (lastBlockData) {
          return (
            lastBlockData.data.last_resource_block.height - nodeData[5] < 20
          );
        } else {
          return false;
        }
      }
    );
    return filteredNodes.length;
  }

  // SORTING HANDLERS

  function handleSortByFail(sortType: string) {
    let sortedItems;
    if (sortType === "fail") {
      sortedItems = [...filteredData].sort((a: any, b: any) => {
        const crashedNodesA: any = numberOfCrashedNodes(a.nodes);
        const crashedNodesB: any = numberOfCrashedNodes(b.nodes);
        return crashedNodesB - crashedNodesA;
      });
    } else if (sortType === "warning") {
      sortedItems = [...filteredData].sort((a: any, b: any) => {
        const issueNodesA: any = numberOfIssueNodes(a.nodes);
        const issueNodesB: any = numberOfIssueNodes(b.nodes);
        return issueNodesB - issueNodesA;
      });
    } else {
      // Default sorting or no sorting
      sortedItems = filteredData;
    }
    setSortedData(sortedItems);
  }

  function compareDataBy(key: any) {
    return function (a: any, b: any) {
      if ("" + a[key] < "" + b[key]) return -1;
      if ("" + a[key] < "" + b[key]) return 1;
      return 0;
    };
  }

  function handleSortData(key: any) {
    let arr = [...filteredData];
    arr.sort(compareDataBy(key));
    setFilteredData(arr);
  }

  //Create fail nodes handler (save command script to DB)

  return (
    <>
      <div className="nodes_container">
        <section className="main">
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="SET & ADD SCHEDULER"
                    value="1"
                    style={{ color: "white" }}
                  />
                  <Tab
                    label="Copy From Node"
                    value="2"
                    style={{ color: "white" }}
                  />
                  <Tab
                    label="Create Nodes"
                    value="3"
                    style={{ color: "white" }}
                  />
                  <Tab
                    label="Modify Nodes"
                    value="4"
                    style={{ color: "white" }}
                  />
                  <Tab
                    label="Delete Node"
                    value="5"
                    style={{ color: "white" }}
                  />
                  <Tab
                    label="Backup From Node"
                    value="6"
                    style={{ color: "white" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ color: "white" }}>
                <div className={"form_container"}>
                  <h1>SET & ADD SCHEDULER</h1>
                  <form
                    className="form-address"
                    onSubmit={handleSubmitScheduler}
                  >
                    <input
                      type="text"
                      placeholder="IP"
                      value={ipInput2}
                      onChange={handleIpInput2}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Computer Name"
                      value={computerName}
                      onChange={handlePCNameInput}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Group Name"
                      value={grName}
                      onChange={handleGrNameInput}
                      style={{ width: "200px" }}
                    />
                    <button type="submit" className="point">
                      Add
                    </button>
                  </form>
                </div>
              </TabPanel>
              <TabPanel value="2" style={{ color: "white" }}>
                <div className={"form_container"}>
                  <h1>COPY FROM NODE</h1>
                  <form className="form-address" onSubmit={handleSubmitFromTo}>
                    <input
                      type="text"
                      placeholder="IP"
                      value={ipInput}
                      onChange={handleIpInput}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="From"
                      value={fromPort}
                      onChange={handleFromInput}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="To: (ex: 1, 1-6, 6)"
                      value={toPort}
                      onChange={handleToInput}
                      style={{ width: "200px" }}
                    />
                    <button type="submit" className="point">
                      Submit
                    </button>
                  </form>
                </div>
              </TabPanel>
              <TabPanel value="3" style={{ color: "white" }}>
                <div className={"form_container"}>
                  <h1>CREATE NODES</h1>
                  <form className="form-address" onSubmit={handleSubmitCreate}>
                    <input
                      type="text"
                      placeholder="IP"
                      value={ipInput3}
                      onChange={handleIpInput3}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Start Port"
                      value={startPort}
                      onChange={handleStartPort}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Number of nodes"
                      value={nodesCount}
                      onChange={handleNodesCount}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="MinerAddress"
                      value={minerAddr}
                      onChange={handleMinerAddress}
                      style={{ width: "200px" }}
                    />
                    <button type="submit" className="point">
                      Submit
                    </button>
                  </form>
                </div>
              </TabPanel>
              <TabPanel value="4" style={{ color: "white" }}>
                <div className={"form_container"}>
                  <h1>MODIFY NODES</h1>
                  <form className="form-address" onSubmit={handleSubmitModify}>
                    <input
                      type="text"
                      placeholder="IP"
                      value={ipInput4}
                      onChange={handleIpInput4}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Start Port"
                      value={startPort_2}
                      onChange={handleStartPort_2}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Number of nodes"
                      value={nodesCount_2}
                      onChange={handleNodesCount_2}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="Miner Address"
                      value={minerAddr_2}
                      onChange={handleMinerAddress_2}
                      style={{ width: "200px" }}
                    />
                    <button type="submit" className="point">
                      Submit
                    </button>
                  </form>
                </div>
              </TabPanel>

              <TabPanel value="5" style={{ color: "white" }}>
                <div className={"form_container"}>
                  <h1>DELETE NODE</h1>
                  <form className="form-address" onSubmit={handleSubmitDelete}>
                    <input
                      type="text"
                      placeholder="IP"
                      value={ipInput5}
                      onChange={handleIpInput5}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="PORT"
                      value={port}
                      onChange={handleDeletePort}
                      style={{ width: "200px" }}
                    />

                    <button
                      type="submit"
                      className="point"
                      style={{ background: "red" }}
                    >
                      Delete
                    </button>
                  </form>
                </div>
              </TabPanel>

              <TabPanel value="6" style={{ color: "white" }}>
                <div className={"form_container"}>
                  <h1>DELETE NODE</h1>
                  <form className="form-address" onSubmit={handleSubmitBackup}>
                    <input
                      type="text"
                      placeholder="IP"
                      value={ipInput6}
                      onChange={handleIpInput6}
                      style={{ width: "200px" }}
                    />
                    <input
                      type="text"
                      placeholder="PORT"
                      value={backupPort}
                      onChange={handleBackupPort}
                      style={{ width: "200px" }}
                    />

                    <button
                      type="submit"
                      className="point"
                      style={{ background: "Green" }}
                    >
                      Backup
                    </button>
                  </form>
                </div>
              </TabPanel>
            </TabContext>
          </Box>

          <div className="info">
            <select
              className="select"
              onChange={(e) => handleSortData(e.target.value)}
            >
              <option className="option" value="">
                Sort
              </option>
              <option className="option" value="ip">
                Sort By IP
              </option>
              <option className="option" value="computer_id">
                Sort By Computer
              </option>
              <option className="option" value="group_name">
                Sort By Group
              </option>
            </select>

            <select
              className="select"
              onChange={(e) => handleSortByFail(e.target.value)}
            >
              <option className="option" value="">
                State
              </option>
              <option className="option" value="fail">
                Sort by Fail
              </option>
              <option className="option" value="warning">
                Sort by Warning
              </option>
            </select>
          </div>
          <div className="table-wrapper">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ClipLoader
                  color={"white"}
                  loading={loading}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <table className="table">
                  {/* header */}
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>IP</th>
                      <th style={{ width: "10%" }}>Computer</th>
                      <th style={{ width: "10%" }}>Group</th>
                      <th style={{ width: "10%" }}>State</th>
                      <th style={{ width: "9%" }}>Nodes</th>
                      <th></th>
                      <th style={{ width: "15%" }}>Updated Time</th>
                      {/* row */}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((item: any, index: any) => (
                      <tr key={index}>
                        <td className="center">{item?.ip}</td>
                        <td className="center">{item?.computer_id}</td>
                        <td className="center">{item?.group_name}</td>
                        <td className="center">
                          {numberOfCrashedNodes(item?.nodes) ===
                          Object.keys(item?.nodes).length ? (
                            <span>Inactive</span>
                          ) : (
                            <span>Active</span>
                          )}
                        </td>
                        {/* nodes count */}
                        <td>
                          {/* false */}
                          <div className="nodes text-error">
                            <div className="nodes-info">
                              <svg viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="nodes-count">
                                {numberOfCrashedNodes(item?.nodes)}
                              </p>
                            </div>
                          </div>
                          {/* stop */}
                          <div className="nodes text-stop">
                            <div className="nodes-info">
                              <svg viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="nodes-count">
                                {numberOfIssueNodes(item?.nodes)}
                              </p>
                            </div>
                          </div>
                          {/* true */}
                          <div className="nodes text-con">
                            <div className="nodes-info">
                              <svg viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              {/* number of nodes */}
                              <p className="nodes-count">
                                {numberOfNormalNodes(item?.nodes)}
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* nodes info */}
                        <td style={{ overflowY: "scroll" }}>
                          {/* false */}
                          {/* <div className={"nodes_wrapper"}> */}
                          <div className="nodes text-error">
                            <div className="nodes-info">
                              {crashedNodes(item?.nodes, item?.ip)}
                            </div>
                          </div>
                          {/* leave */}
                          <div className="nodes text-stop">
                            <div className="nodes-info">
                              {issueNodes(item?.nodes, item?.ip)}
                            </div>
                          </div>
                          {/* success */}
                          <div className="nodes text-con">
                            <div key={index}>
                              <div className="nodes-info">
                                {normalNodes(item?.nodes, item?.ip)}
                                <RestartAllButton ipAddr={item?.ip} />
                                <DeleteAllButton ipAddr={item?.ip} />
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </td>
                        <td>
                          <div className="center">{item?.timestamp}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
