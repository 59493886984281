import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AwsApiService from "../services/awsApiService";
import "../styles/nodes.css";

// Assume AwsApiService is imported and initialized correctly

const RestartAllButton = ({ ipAddr }: { ipAddr: any }) => {
  console.log("ip:::", ipAddr);
  const [isDisabled, setIsDisabled] = useState(false);

  // Generate a unique key for localStorage based on the button's properties
  const disabledUntilKey = `disabledUntil-${ipAddr}`;

  useEffect(() => {
    // Check if this specific button should still be disabled
    const disabledUntil = localStorage.getItem(disabledUntilKey);
    if (disabledUntil && new Date(disabledUntil) > new Date()) {
      setIsDisabled(true);
      const timeoutDuration =
        new Date(disabledUntil).getTime() - new Date().getTime();
      setTimeout(() => setIsDisabled(false), timeoutDuration);
    }
  }, [disabledUntilKey]); // Depend on the unique key

  const handleClick = async () => {
    if (isDisabled) return;

    if (isDisabled) return;
    // eslint-disable-next-line no-restricted-globals
    const isSure = confirm("Are you sure?");
    if (!isSure) {
      alert("Canceled!");
      return;
    }

    const apiService = new AwsApiService();
    await apiService.handleAllStart(ipAddr);

    setIsDisabled(true);
    const disabledUntil = new Date(new Date().getTime() + 300000); // 5 minutes from now
    localStorage.setItem(disabledUntilKey, disabledUntil.toISOString());
    window.location.reload();

    setTimeout(() => {
      setIsDisabled(false);
      localStorage.removeItem(disabledUntilKey); // Clear the key from localStorage once the button is re-enabled
    }, 300000);
  };

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      disabled={isDisabled}
      className={"yellow_button"}
      style={{
        backgroundColor: isDisabled ? "grey" : "#FAA300",
        fontSize: "11px",
        height: "50px",
        maxWidth: "100px",
        minWidth: "100px",
        padding: "5px",
      }}
    >
      Restart & start mining
    </Button>
  );
};

export default RestartAllButton;
