import { useState } from "react";
import "../styles/home.css";
import { useNavigate } from "react-router-dom";

export function HomePage() {
  const [groupName, setGroupName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    localStorage.setItem("groupName", groupName);
    navigate("/nodes");
  };

  const handleInputChange = (e: any) => {
    setGroupName(e.target.value);
  };
  return (
    <div className="home_container">
      <form className="form-address" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Classic Miner Address"
          value={groupName}
          onChange={handleInputChange}
        />
        <button type="submit" className="point">
          Confirm
        </button>
      </form>
    </div>
  );
}
